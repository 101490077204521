import React, {FormEvent, FormHTMLAttributes, useCallback, useRef, useState} from 'react';
import "./Pages.Contact.scss";
import emailjs from '@emailjs/browser';
import {NavLink} from "react-router-dom";
interface Props {
	isEnglish: boolean
}


const PagesContact = ({isEnglish}: Props) => {

	const form =useRef<HTMLFormElement>(null);

	const [success, setSuccess] = useState<boolean>(false);

	const sendEmail = useCallback((e: any) => {
		e.preventDefault();

		if (form.current !== null) {
			emailjs.sendForm('service_w2jy25z', 'template_gyl972g', form.current, 'Ogj4WjNw8__Vh3jZM')
				.then((result) => {
					setSuccess(true);
				}, (error) => {

				});
		}
	}, []);

	const onInvalidInput = useCallback((e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		e.currentTarget.setCustomValidity(isEnglish ? "Please fill out this field" : "SVP remplir ce champ");
	}, []);

	const onValidInput = useCallback((e:FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		e.currentTarget.setCustomValidity("");
	}, []);

	return <div id="contact">
		<h3 id="contact-title">Contact</h3>
		<div id={"contact-text"}>
			<p>{isEnglish ? "Do you have a question or a comment?" : "As-tu une question ou un commentaire? Gêne-toi surtout pas!"}</p>
			<div className={"pWithLink"}>
				<p>{isEnglish ? "We also invite you to visit our page " : "On t’invite également à aller visiter notre page "}</p>
				<NavLink to={isEnglish ? "/en/infos/" : "/fr/infos/"}>
					<p> FAQ</p>
				</NavLink>
			</div>
			<p>{isEnglish ? "We will respond to you as soon as possible." : "Nous te répondrons dans les plus brefs délais."}</p>
		</div>
		<form id={"contact-form"} ref={form} onSubmit={sendEmail}>
			<div id={"contact-form-fields"}>
				<label>{isEnglish ? "First name*" : "Prénom*" }</label>
				<input type="text" name="first_name" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Email*" : "Courriel*" }</label>
				<input type="text" name="email" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Last name*" : "Nom*" }</label>
				<input type="text" name="last_name" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Phone*" : "Téléphone*" }</label>
				<input type="text" name="phone" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
			</div>
			<div id={"contact-form-message"}>
				<label>Message*</label>
				<textarea name="message" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
			</div>
			<div id={"contact-form-submit"}>
				<input type="submit" value={isEnglish ? "Send" : "Envoyer"} />
			</div>
		</form>
		{success && <p id={"contact-success"}>
			{isEnglish ? "Email was sent with success ! We will respond to you in the shortest possible delay" : "Le courriel a été envoyé avec succès ! Nous vous répondrons dans les plus brefs délais."}
		</p>}
	</div>
}

export default PagesContact;
