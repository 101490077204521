import React, {FormEvent, useCallback, useRef, useState} from 'react';
import "./Pages.Merchant.scss"
import emailjs from "@emailjs/browser";

interface Props {
	isEnglish: boolean;
}

const PagesMerchant = ({isEnglish}: Props) => {
	const form =useRef<HTMLFormElement>(null);

	const [success, setSuccess] = useState<boolean>(false);

	const sendEmail = useCallback((e: any) => {
		e.preventDefault();

		if (form.current !== null) {
			emailjs.sendForm('service_w2jy25z', 'template_xkv5st7', form.current, 'Ogj4WjNw8__Vh3jZM')
				.then((result) => {
					setSuccess(true);
				}, (error) => {

				});
		}
	}, []);

	const onInvalidInput = useCallback((e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		e.currentTarget.setCustomValidity(isEnglish ? "Please fill out this field" : "SVP remplir ce champ");
	}, []);

	const onValidInput = useCallback((e:FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		e.currentTarget.setCustomValidity("");
	}, []);

	return <div id="merchant">
		<h3 id="merchant-title">{isEnglish ? "Becomme a merchant" : "Devenir un marchant"}</h3>
		<div id={"merchant-text"}>
			<p>{isEnglish ? "Interested in becoming a retailer?" : "Tu es intéressé à devenir un détaillant?"}</p>
			<p>{isEnglish ? "Please answer the various questions below!" : "Merci de répondre aux différentes questions!"}</p>
			<p>{isEnglish ? "Someone will contact you as soon as possible." : "Quelqu’un te contacte le plus rapidement possible."}</p>
		</div>
		<form id={"merchant-form"} ref={form} onSubmit={sendEmail}>
			<div id={"merchant-form-fields"}>
				<label>{isEnglish ? "Name of contact person*" : "Nom de la personne contact*" }</label>
				<input type="text" name="name" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Email*" : "Courriel*" }</label>
				<input type="text" name="email" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Website of commerce" : "Site web du commerce" }</label>
				<input type="text" name="website" onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Enterprise name*" : "Nom de l'entreprise*" }</label>
				<input type="text" name="enterprise_name" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Phone*" : "Téléphone*" }</label>
				<input type="text" name="phone" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Address of commerce" : "Adresse du commerce" }</label>
				<input type="text" name="address" onInvalid={onInvalidInput} onInput={onValidInput}/>
				<label>{isEnglish ? "Type of commerce*" : "Type de commerce*" }</label>
				<input type="text" name="type" required={true} onInvalid={onInvalidInput} onInput={onValidInput}/>
			</div>
			<div id={"merchant-form-message"}>
				<label>{isEnglish ? "Comments, questions" : "Commentaires, questions"}</label>
				<textarea name="message" onInvalid={onInvalidInput} onInput={onValidInput}/>
			</div>
			<div id={"merchant-form-submit"}>
				<input type="submit" value={isEnglish ? "Send" : "Envoyer"} />
			</div>
		</form>
		{success && <p id={"merchant-success"}>
			{isEnglish ? "Email was sent with success ! Thanks for your interest!" : "Le courriel a été envoyé avec succès, merci de ton intérêt! "}
    </p>}
	</div>
}

export default PagesMerchant;