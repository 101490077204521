import React from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query'
import QueryableApp from "./QueryableApp";

const App = () => {

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <QueryableApp/>
    </QueryClientProvider>
  );
}
export default App;
