import React from 'react';
import "./Pages.Products.scss";
import {Product} from "../../../Models/Product";

interface Props {
	isEnglish: boolean;
	products: Product[];
}


const PagesProducts = ({isEnglish, products}: Props) => {

	if (products.length === 0)
		return <p>Loading...</p>

	return <div id="products">
		<h3 id={"products-title"}>{isEnglish ? "Products" : "Produits"}</h3>
		<div id={"products-text"}>
			<h4 id={"products-text-title"}>{isEnglish ? "BULK INFO ON LEGUMES" : "INFOS EN VRAC SUR LES LÉGUMINEUSES"}</h4>
			<ul id={"products-text-list"}>
				<li>{isEnglish ? "Chickpeas and beans are part of the legume family (Fabaceae)" : "Les pois chiches et les haricots font partis de la famille des légumineuses (Fabaceae)"}</li>
				<li>{isEnglish ? "These are vegetables from pod plants" : " Ce sont des légumes provenant de plantes à gousses"}</li>
				<li>{isEnglish ? "Good source of protein" : "Bonne source de protéine"}</li>
				<li>{isEnglish ? "Good source of fiber" : "Bonne source de fibre"}</li>
				<li>{isEnglish ? "Good source of iron" : "Bonne source de fer"}</li>
				<li>{isEnglish ? "Good source of folic acid, calcium, magnesium, zinc and potassium" : "Bonne source d’acide folique, calcium, magnésium, zinc et potassium"}</li>
				<li>{isEnglish ? "Low in fat" : "Faible en matière grasse"}</li>
				<li>{isEnglish ? "Good for fighting cancer, diabetes, anemia and heart disease" : "Bonnes pour contrer le cancer, le diabète, l’anémie et les maladies de coeur"}</li>
				<li>{isEnglish ? "Pulses are proteins with a low carbon footprint which is beneficial for the reduction of greenhouse gases" : "Les légumineuses sont des protéines ayant une faible empreinte de carbone ce qui est  bénéfique pour la réduction des gaz à effet de serre"}</li>
				<li>{isEnglish ? "Chickpeas are beneficial for agriculture in that they help to improve soil fertility and thus increase land productivity" : "Les pois chiches sont bénéfiques pour l’agriculture dans l’optique qu’ils aident à améliorer la  fertilité des sols et ainsi accroître la productivité des terres"}</li>
			</ul>
		</div>
		<div id={"products-grid"}>
			{products.map((product, idx) => {
				return (
					<div className={"product"} key={`Product-${idx}`}>
						<h4 className={"product-name"}>{isEnglish ? product.nameEnglish : product.nameFrench}</h4>
						<div className={"product-images"}>
							<img
								className={"product-images-main"}
								src={product.imagePath}
								alt={isEnglish ? "Image unavailable" : "Image non disponible"}
							/>
							<img
								className={"product-images-nutrition"}
								src={product.nutritionPath}
								alt={isEnglish ? "Nutrition unavailable" : "Nutrition non disponible"}
							/>
						</div>
					</div>
				)
			})}
		</div>
	</div>
}

export default PagesProducts;