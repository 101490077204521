import React, {useEffect, useState} from 'react';
import "./Pages.Videos.scss"
import {Category} from "../../../Models/Category";
import {Recipe} from "../../../Models/Recipe";
import FilteredGrid from "../../GenericComponents/GridWithCategoriesFilter/FilteredGrid";
import {FilteredGridElement} from "../../../Models/FilteredGridElement";

interface Props {
	isEnglish: boolean;
	categories: Category[];
	recipes: Recipe[];
}

const PagesVideos = ({isEnglish, categories, recipes}: Props) => {
	const [videoElements, setVideoElements] = useState<FilteredGridElement[]>([]);

	useEffect(() => {
		const videoElements: FilteredGridElement[] = recipes.filter((recipe) => {
			return recipe.youtubeVideoID !== "";
		}).map((recipeWithVideo) => {
			return {
				categories: recipeWithVideo.categories,
				name: isEnglish ? recipeWithVideo.nameEnglish : recipeWithVideo.nameFrench,
				id: recipeWithVideo.id,
				element: (
					<div className={"grid-video"}>
						<iframe
							allow="fullscreen;"
							src={`https://www.youtube.com/embed/${recipeWithVideo.youtubeVideoID}`}
						/>
						<p className={"grid-video-title"}>
							{isEnglish ? recipeWithVideo.nameEnglish : recipeWithVideo.nameFrench}
						</p>
					</div>
				)
			}
		});

		setVideoElements(videoElements);
	}, [recipes, isEnglish]);

	return <div id={"videos"}>
		<h3 id={"videos-title"}>{isEnglish ? "Videos" : "Vidéos"}</h3>
		<FilteredGrid categories={categories} elements={videoElements} isEnglish={isEnglish} gridElementType={"videos"} hasOnClickTo={false}/>
	</div>
}

export default PagesVideos;