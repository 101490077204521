import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./Header.scss"
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {getDownloadURL, ref} from "firebase/storage"
import {storage} from "../../Firebase/firebase";
import {Recipe} from "../../Models/Recipe";
import {Size, useWindowSize} from "../../Hooks/useWindowSize";

interface Props {
    isEnglish: boolean;
	recipes: Recipe[];
}

const Header = ({isEnglish, recipes}: Props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const size: Size = useWindowSize();

	//#region fetchImage

	const [headerLogoURL, setHeaderLogoURL] = useState<string>("");
	const [fbLogoURL, setFbLogoURL] =useState<string>("");
	const [instaLogoURL, setInstaLogoURL] =useState<string>("");
	const [tiktokLogoURL, setTiktokLogoURL] =useState<string>("");

	useEffect(() => {
		getDownloadURL(ref(storage, "logo_header.png")).then((url) => {
			setHeaderLogoURL(url);
		});

		getDownloadURL(ref(storage, "icons8-facebook.svg")).then((url) => {
			setFbLogoURL(url);
		});

		getDownloadURL(ref(storage, "icons8-instagram.svg")).then((url) => {
			setInstaLogoURL(url);
		});

		getDownloadURL(ref(storage, "icons8-tiktok.svg")).then((url) => {
			setTiktokLogoURL(url);
		});
	}, []);

	const openFacebookInNewPage = useCallback(() => {
		window.open("https://www.facebook.com/profile.php?id=100077663182733" , "_blank");
	}, []);

	const openInstaInNewPage = useCallback(() => {
		window.open( "https://www.instagram.com/sifacilecuisine/?fbclid=IwAR0lQjxc4O9VXqTwq6Onqz3AvV3i4Q-hTpcq_SqrpMQUSJ3FwhekKUazpNU", "_blank");
	}, []);

	const openTikTokInNewPage = useCallback(() => {
		window.open("https://www.tiktok.com/@sifacilecuisine?fbclid=IwAR051JkwNZGnmTMwRZN-kp5bUmtRKwBg7aJoQERJO1l-FD1CY5prFO2U5QU", "_blank");
	}, []);

	//#endregion fetchImage

	//#region change language

	const changeLanguage = useCallback(() => {
		let pathname = location.pathname;
		if (isEnglish) { //go to french
			pathname = pathname.replace("en", "fr");
			pathname = pathname.replace("products", "produits");
			pathname = pathname.replace("recipes", "recettes");
			pathname = pathname.replace("stores", "magasins");
			pathname = pathname.replace("partners", "partenaires");
			pathname = pathname.replace("become-a-merchant", "devenir-un-marchand");
			pathname = pathname.replace("privacy-policy", "confidentialité");
		} else { //go to english
			pathname = pathname.replace("fr", "en");
			pathname = pathname.replace("produits","products");
			pathname = pathname.replace("recettes","recipes");
			pathname = pathname.replace("magasins","stores");
			pathname = pathname.replace("partenaires", "partners");
			pathname = pathname.replace("devenir-un-marchand", "become-a-merchant");
			pathname = pathname.replace("confidentialit%C3%A9", "privacy-policy");
		}

		let search = location.search;

		if (search !== "") {
			//then we have a recipe in the url
			const id = search.slice(search.indexOf('=') + 1, search.indexOf('&'));
			const recipe = recipes.find(recipe => recipe.id === id);
			if (recipe === undefined) {
				search = "";
			} else {
				const name = isEnglish ? recipe.nameFrench : recipe.nameEnglish;
				search = "?id=" + id + "&name=" + name.replaceAll(' ', '+');
			}
		}

		navigate(pathname + search);
	}, [location, navigate]);

	//#endregion change language

	//#region header menu

	const navBar = useMemo(() => {
		return (
			<nav className="header-navBar">
				<NavLink to={isEnglish ? "/en/products/" : "/fr/produits/"} className="header-navBar-navLink">
					<div>
						<p>{isEnglish ? "Products" : "Produits"}</p>
					</div>
				</NavLink>
				<NavLink to={isEnglish ? "/en/recipes/" : "/fr/recettes/"} className="header-navBar-navLink">
					<div>
						<p>{isEnglish ? "Recipes" : "Recettes"}</p>
					</div>
				</NavLink>
				{/*<NavLink to={isEnglish ? "/en/videos/" : "/fr/videos/"} className="header-navBar-navLink">
					<div>
						<p>{isEnglish ? "Videos" : "Vidéos"}</p>
					</div>
				</NavLink>*/}
				<NavLink to={isEnglish ? "/en/stores/" : "/fr/magasins/"} className="header-navBar-navLink">
					<div>
						<p>{isEnglish ? "Points of sale" : "Points de vente"}</p>
					</div>
				</NavLink>
				<NavLink to={isEnglish ? "/en/infos/" : "/fr/infos/"} className="header-navBar-navLink">
					<div>
						<p>{isEnglish ? "About" : "À propos"}</p>
					</div>
				</NavLink>
			</nav>
		)
	}, [isEnglish]);

	const options = useMemo(() => {
		return (
			<div className="header-options">
				<img src={fbLogoURL} alt={"fb"} onClick={openFacebookInNewPage}/>
				<img src={instaLogoURL} alt={"insta"} onClick={openInstaInNewPage}/>
				{/*<img src={tiktokLogoURL} alt={"tiktok"} onClick={openTikTokInNewPage}/>*/}
				<button type="button" id={"header-options-language"} onClick={changeLanguage}>
					{isEnglish ? "FR" : "EN"}
				</button>
			</div>
		)
	}, [tiktokLogoURL, instaLogoURL, fbLogoURL, isEnglish, changeLanguage]);

	/**
	 * widescreen menu
	 */
	const wideMenu = useMemo(() => {
		return (
			<>
				{navBar}
				{options}
			</>
		)
	}, [navBar, options]);

	/**
	 * Compact menu for small screen
	 */

	const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);

	const toggleHamburger = useCallback(() => {
		setHamburgerOpen(!hamburgerOpen);
	}, [hamburgerOpen]);

	const compactMenu = useMemo(() => {
		return (
			<>
				<div id={"header-hamburger"} onClick={toggleHamburger}>
					<div className={"burger"}/>
					<div className={"burger"}/>
					<div className={"burger"}/>
				</div>
				{hamburgerOpen && (
					<div id={"header-hamburger-expanded"} onClick={toggleHamburger}>
						{navBar}
						{options}
					</div>
				)}
			</>
		)
	}, [toggleHamburger, wideMenu]);

	const getMenu = useMemo(() => {
		if (!size.width)
			return null; //we'll have it next frame
		if (size.width > 800) {
			if (hamburgerOpen)
				setHamburgerOpen(false);
			return wideMenu;
		} else
			return compactMenu;
	},  [size, wideMenu, compactMenu]);

	//#endregion header menu

	return <div id="header">
		<NavLink to={isEnglish ? "/en/" : "/fr/"}>
			<img id="header-title" src={headerLogoURL} alt={"Si Facile"}/>
		</NavLink>
		{getMenu}
	</div>;
}

export default Header;