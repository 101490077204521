import React, {useCallback, useEffect, useMemo, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import "./Pages.Map.scss";
import {MapMerchant} from "../../../Models/MapMerchant";


//#region Markers

interface MarkersProps {
	lat: number;
	lng: number;
	onClick: () => void;
	isSelected: boolean;
	idx: string;
	name: string;
	address: string;
	phone: string;
	website: string;
	isEnglish: boolean;
}

const Markers = ({onClick, isSelected, idx, name, address, phone, isEnglish, website}: MarkersProps) => {
	return (
		<div className={"marker-container"}>
			{isSelected && <div className={"marker-details-container"}>
					<div className={"marker-details-content"}>
							<h5 className={"marker-details-content-name"}>{name}</h5>
							<div className={"marker-details-content-content"}>
									<p>{(isEnglish ? "Address: " : "Addresse: ") + address}</p>
									<p>{(isEnglish ? "Phone: " : "Téléphone: ") + phone}</p>
									<span>
											<p>{isEnglish ? "Website: " : "Site web: "}</p>
											<a href={`https://${website}`} target={"_blank"}>{website}</a>
									</span>
							</div>
					</div>
					<div className={"marker-details-pointy"}/>
			</div>}
			<div className={"marker"}>
				<div
					className={`marker-pin`}
					onClick={onClick}
				>
					<p>{idx}</p>
				</div>
			</div>
		</div>
	)
}

//#endregion Markers

interface Coord {
	lat: number,
	lng: number
}

interface MerchantDistancePair {
	merchant: MapMerchant;
	distance: number | null;
}

interface Props {
	isEnglish: boolean
	mapMerchants: MapMerchant[];
}

const PagesMap = ({isEnglish, mapMerchants}: Props) => {
	useEffect(() => {
		Geocode.setApiKey("AIzaSyD0XeOH4xNP0yeJ2V062H8gB9hOsb9dRSI");
	}, []);

	const [merchantDistancePairs, setMerchantDistancePairs] = useState<MerchantDistancePair[]>(
		mapMerchants.map(mapMerchant => {
			return {
				merchant: mapMerchant,
				distance: null
			}
		})
	)

	//#region map params
	const defaultCenter = useMemo<Coord>(() => {
		return {lat:  45.5019, lng: -73.6674}
	}, []);

	const defaultZoom = useMemo<number>(() => 10, []);

	//Need to work with an interface so the ptr change even tho we assign the same value of amount hence react rerender
	const [zoom, setZoom] = useState<number>(defaultZoom);
	const [center, setCenter] = useState<Coord>(defaultCenter);

	//#endregion map params

	//#region selection

	const [selectedMerchantIdx, setSelectedMerchantIdx] = useState<number>(-1);

	const onMerchantClick = useCallback((idx: number) => {
		return () => {
			setSelectedMerchantIdx(idx);
			setZoom(10);
			setCenter({lat: merchantDistancePairs[idx].merchant.latitude, lng: merchantDistancePairs[idx].merchant.longitude});
		}
	}, [merchantDistancePairs]);

	//#endregion selection

	//#region Search
	const [searchText, setSearchText] = useState<string>("");
	const [searchCoord, setSearchCoord] = useState<Coord | null>(null);
	const [searchError, setSearchError] = useState<boolean>(false);

	const searchOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.currentTarget.value)
	}, []);

	const searchOnKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.currentTarget.blur();
		}
	}, []);

	const searchOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		Geocode.fromAddress(e.currentTarget.value.toLowerCase()).then(response => {
			const coord: Coord = response.results[0].geometry.location;
			setSearchError(false);
			setSearchText(response.results[0].formatted_address);
			setSearchCoord(coord);
			setCenter(coord);
			sortMerchant(coord);
		}).catch(err => {
			setSearchError(true);
			setMerchantDistancePairs(		mapMerchants.map(mapMerchant => {
				return {
					merchant: mapMerchant,
					distance: null
				}
			}));
		});
		setSelectedMerchantIdx(-1);
	}, [Geocode, mapMerchants]);

	const sortMerchant = useCallback((fromCoord: Coord) => {
		const merchantDistancePairs: MerchantDistancePair[] = mapMerchants.map((merchant) => {
			const toCoord = {lat: merchant.latitude, lng: merchant.longitude}
			const distance = getDistanceKMBetweenCoord(fromCoord, toCoord);
			return {
				merchant,
				distance
			}
		}).sort((pairA, pairB) => {
			if (pairA.distance > pairB.distance)
				return 1;
			else
				return -1;
		});

		setMerchantDistancePairs(merchantDistancePairs);
	}, [mapMerchants]);

	const getDistanceKMBetweenCoord = useCallback((a: Coord, b: Coord) => {
		const earthRadiusKM = 6371;
		const lat1Rad = a.lat * Math.PI / 180;
		const lat2Rad = b.lat * Math.PI / 180;
		const lng1Rad = a.lng * Math.PI / 180;
		const lng2Rad = b.lng * Math.PI / 180;
		return Math.abs(
			Math.acos(
				Math.sin(lat1Rad) * Math.sin(lat2Rad) + Math.cos(lat1Rad)*Math.cos(lat2Rad)*Math.cos(lng2Rad - lng1Rad)
			) * earthRadiusKM
		);
	}, []);

	//#endregion Search

	return  <div id="map">
		<h3 id="map-title">{isEnglish ? "Points of sale" : "Points de vente"}</h3>
		<div id={"map-content"}>
			<div id={"map-content-googleMap"}>
				<GoogleMapReact
					bootstrapURLKeys={{
						key: "AIzaSyD0XeOH4xNP0yeJ2V062H8gB9hOsb9dRSI",
						language: isEnglish ? "en" : "fr"
					}}
					yesIWantToUseGoogleMapApiInternals
					defaultCenter={defaultCenter}
					defaultZoom={defaultZoom}
					zoom={zoom}
					center={center}
				>
					{merchantDistancePairs.map((merchantDistancePair, idx) => {
						const mapMerchant = merchantDistancePair.merchant;
						return <Markers
							lat={mapMerchant.latitude}
							lng={mapMerchant.longitude}
							onClick={onMerchantClick(idx)}
							isSelected={idx === selectedMerchantIdx}
							key={`marker-${idx}`}
							idx={(idx + 1).toString()}
							isEnglish={isEnglish}
							name={mapMerchant.name}
							website={mapMerchant.website}
							phone={mapMerchant.phone}
							address={mapMerchant.address}
						/>
					})}
				</GoogleMapReact>
			</div>
			<div id={"map-content-merchant"}>
				<div id={"map-content-merchant-search"}>
					<input
						id={"map-content-merchant-search-input"}
						onKeyDown={searchOnKeyDown}
						onBlur={searchOnBlur}
						onChange={searchOnChange}
						value={searchText}
						placeholder={isEnglish ? "Enter City / Zip code: " : "Entrez une ville / code postale: "}>
					</input>
				</div>
				{searchError &&
            <p id={"map-content-merchant-search-error"}>
							{isEnglish ? "No results found" : "Aucun résultat trouvé"}
            </p>
				}
				<div id={"map-content-merchant-list"}>
					{merchantDistancePairs.map((merchantDistancePair, idx) => {
						const mapMerchant = merchantDistancePair.merchant;
						let distance = null;
						if (merchantDistancePair.distance != null)
							distance = Math.round(merchantDistancePair.distance * 10) / 10
						return (
							<div
								className={`map-content-merchant-list-element${idx === selectedMerchantIdx ? "-selected" : ""}`}
								key={`merchant-${idx}`}
							>
								<div className={"map-content-merchant-list-element-title"} onClick={onMerchantClick(idx)}>
									<h5 className={"map-content-merchant-list-element-title-idx"}>{(idx + 1).toString()}</h5>
									<h5 className={"map-content-merchant-list-element-title-name"}>{mapMerchant.name}</h5>
								</div>
								<div className={"map-content-merchant-list-element-content"}>
									<p><i className={"material-symbols-outlined"}>location_on</i>{(isEnglish ? "Address: " : "Addresse: ") + mapMerchant.address}</p>
									<p><i className={"material-symbols-outlined"}>call</i>{(isEnglish ? "Phone: " : "Téléphone: ") + mapMerchant.phone}</p>
									<span>
										<p><i className={"material-symbols-outlined"}>captive_portal</i>{isEnglish ? "Website: " : "Site web: "}</p>
										<a href={`https://${mapMerchant.website}`} target={"_blank"}>{mapMerchant.website}</a>
									</span>
									{distance && <p>{(isEnglish ? "Distance: " : "Distance: ") + distance + "km"}</p>}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	</div>
}

export default PagesMap;