import React, {useEffect, useState} from "react";
import "./Pages.Recipes.scss"
import FilteredGrid from "../../GenericComponents/GridWithCategoriesFilter/FilteredGrid";
import {Category} from "../../../Models/Category";
import {Recipe} from "../../../Models/Recipe";
import {FilteredGridElement} from "../../../Models/FilteredGridElement";
import {useLocation, useNavigate} from "react-router-dom";
import PagesRecipesRecipe from "./Pages.Recipes.Recipe";

interface Props {
	isEnglish: boolean;
	categories: Category[];
	recipes: Recipe[];
}


const PagesRecipes = ({isEnglish, categories, recipes}: Props) => {
	const location = useLocation();
	const navigate = useNavigate();

	const [recipesElement, setRecipesElement] = useState<FilteredGridElement[]>([]);
	const [recipeId, setRecipeId] = useState<string>("");

	useEffect(() => {
		const recipesElement: FilteredGridElement[] = recipes.map((recipe, idx) => {
			return {
				categories: recipe.categories,
				name: isEnglish ? recipe.nameEnglish : recipe.nameFrench,
				id: recipe.id,
				element: (
					<div className={"grid-recipe"} key={`grid-recipe-${idx}`}>
						<img
							className={"grid-recipe-image"}
							src={recipe.imagePath}
							alt={isEnglish ? "Image unavailable" : "Image non disponible"}
						/>
						<p className={"grid-recipe-title"}>
							{isEnglish ? recipe.nameEnglish : recipe.nameFrench}
						</p>
						<p className={"grid-recipe-time"}>
							{`${isEnglish ? "Total time: " : "Temps total:"} ${(recipe.preparationTime + recipe.cookingTime)}min.` }
						</p>
					</div>
				)
			}
		});

		setRecipesElement(recipesElement);
	}, [recipes, isEnglish]);

	useEffect(() => {
		const search = location.search;

		if (search === "") {
			setRecipeId("");
		} else {
			const id = search.slice(search.indexOf('=') + 1, search.indexOf('&'));
			setRecipeId(id);
		}
	}, [location]);

	if (recipeId) {
		const recipe = recipes.find(recipe => recipe.id === recipeId);

		if (recipe === undefined) {
			navigate(isEnglish ? "/en/recipes/" : "/fr/recettes/");
			return null;
		} else {
			return <PagesRecipesRecipe isEnglish={isEnglish} recipe={recipe}/>;
		}
	} else {
		return <div id={"recipes"}>
			<h3 id={"recipes-title"}>{isEnglish ? "Recipes" : "Recettes"}</h3>
			<FilteredGrid categories={categories} elements={recipesElement} isEnglish={isEnglish} gridElementType={"recipes"} hasOnClickTo={true}/>
		</div>
	}
}

export default PagesRecipes;