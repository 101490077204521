import React from 'react';
import "./Pages.Homepage.scss";
import {Product} from "../../../Models/Product";
import {NavLink} from "react-router-dom";

interface Props {
	isEnglish: boolean
	products: Product[];
}

const PagesHomepage = ({isEnglish, products}: Props) => {
	return <div id={"main"}>
		<div id={"main-cover"} />
		<div id={"main-products"}>
			<h2 id={"main-products-title"}>{isEnglish ? "Our favorite products" : "Nos produits favoris"}</h2>
			<div id={"main-products-images"}>
				{products.slice(0, 4).map(product => {
					return (
						<img src={product.imagePath}/>
					)
				})}
			</div>
		</div>
		<div id={"main-navBar"}>
			<NavLink to={isEnglish ? "/en/products/" : "/fr/produits/"} className="main-navBar-navLink">
				<div style={{backgroundColor: "#ffd8a3"}}>
					<p>{isEnglish ? "See more products" : "Voir plus de produits"}</p>
				</div>
			</NavLink>
			<NavLink to={isEnglish ? "/en/recipes/" : "/fr/recettes/"} className="main-navBar-navLink">
				<div style={{backgroundColor: "#f7ec9b"}}>
					<p>{isEnglish ? "Access our recipes" : "Accéder à nos recettes"}</p>
				</div>
			</NavLink>
			<NavLink to={isEnglish ? "/en/stores/" : "/fr/magasins/"} className="main-navBar-navLink">
				<div style={{backgroundColor: "#f7c59b"}}>
					<p>{isEnglish ? "Our points of sale" : "Nos points de vente"}</p>
				</div>
			</NavLink>
			<NavLink to={isEnglish ? "/en/infos/" : "/fr/infos/"} className="main-navBar-navLink">
				<div style={{backgroundColor: "#ffd8a3"}}>
					<p>{isEnglish ? "Why Si Facile ?" : "Pourquoi Si Facile ?"}</p>
				</div>
			</NavLink>
		</div>
	</div>
}

export default PagesHomepage;