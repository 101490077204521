import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./FilteredGrid.scss"
import {Category} from "../../../Models/Category";
import {FilteredGridElement} from "../../../Models/FilteredGridElement";
import {NavLink} from "react-router-dom";
import {Size, useWindowSize} from "../../../Hooks/useWindowSize";

interface Props {
	categories: Category[];
	elements: FilteredGridElement[];
	isEnglish: boolean;
	gridElementType: string;
	hasOnClickTo: boolean;
}

const FilteredGrid = ({categories, elements, isEnglish, gridElementType, hasOnClickTo}: Props) => {
	const size: Size = useWindowSize();

	//#region Menu

	const [searchFilter, setSearchFilter] = useState<string>("")

	const searchOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		setSearchFilter(e.currentTarget.value.toLowerCase());
	}, []);

	const searchOnKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.currentTarget.blur();
		}
	}, []);

	const [categoryFilter, setCategoryFilter] = useState<string[]>([]);

	const onCategoryClicked = useCallback((e: React.MouseEvent) => {
		const clickedCategory = e.currentTarget.id;

		if (categoryFilter.includes(clickedCategory)) {
			setCategoryFilter([...categoryFilter.filter(category => category !== clickedCategory)]);
		} else {
			setCategoryFilter([...categoryFilter, clickedCategory]);
		}
	}, [categoryFilter]);

	const [displayElements, setDisplayElements] = useState<FilteredGridElement[]>([]);

	useEffect(() => {
		setDisplayElements(elements
			.filter((element) => {
				if (categoryFilter.length === 0)
					return true;
				return element.categories.filter((category) => {
					return categoryFilter.includes(category)
				}).length === categoryFilter.length;
			})
			.filter((element) => {
				return element.name.toLowerCase().includes(searchFilter);
			}));
	}, [searchFilter, categoryFilter, elements]);

	const searchBar = useMemo(() => {
		return (
			<div className={"search"}>
				<input
					className={"search-input"}
					onKeyDown={searchOnKeyDown}
					onBlur={searchOnBlur}
					placeholder={isEnglish ? "Search..." : "Rechercher..."}
				/>
			</div>
		);
	}, [isEnglish, searchOnBlur, searchOnKeyDown]);

	const categoriesFilter = useMemo(() => {
		return (
			<div className={"filters"}>
				{categories.map((category, idx) => {
					const isSelected = categoryFilter.includes(category.value);
					return (
						<span
							className={`filters-category${isSelected ? "-selected" : ""}`}
							key={`category-${idx}`}
							id={category.value}
							onClick={onCategoryClicked}
						>
									{isEnglish ? category.textEnglish : category.textFrench}
								</span>
					)
				})}
			</div>
		);
	}, [categories, categoryFilter, isEnglish]);

	const sideBar = useMemo(() => {
		return (
			<div className={"sidebar"}>
				{searchBar}
				{categoriesFilter}
			</div>
		);
	}, [categoriesFilter, searchBar]);

	const [topbarMenuOpen, setTopbarMenuOpen] = useState<boolean>(false);

	const toggleTopBarMenu = useCallback(() => {
		setTopbarMenuOpen(!topbarMenuOpen);
	}, [topbarMenuOpen]);

	const topBar = useMemo(() => {
		return (
			<div className={"topbar"}>
				<div className={"topbar-options"}>
					{searchBar}
					<div className={"filter-button"} onClick={toggleTopBarMenu}>
						{topbarMenuOpen ?
							<p>{isEnglish ? "Close categories" : "Categories"}</p> :
							<p>{isEnglish ? "Select categories" : "Categories"}</p>
						}
					</div>
				</div>
				{topbarMenuOpen && categoriesFilter}
			</div>
		);
	}, [topbarMenuOpen, toggleTopBarMenu, categoriesFilter, searchBar]);

	//#endregion Menu

	const gridContainer = useMemo(() => {
		return (
			<div className={`filtered-grid-container`}>
				<div className={`filtered-grid-container-grid-${gridElementType}`}>
					{displayElements.length > 0 ?
						displayElements.map((displayElement, idx) => {
							if (hasOnClickTo) {
								return (
									<NavLink
										to={(isEnglish ? "/en/recipes/" : "/fr/recettes/") + "?id=" + displayElements[idx].id + "&name=" + displayElements[idx].name.replaceAll(' ', '+')}
										className={"grid-navLink"}
									>
										{displayElement.element}
									</NavLink>
								);
							} else {
								return displayElement.element;
							}}) :
						<p>{isEnglish ? "No element to display" : "Aucun element a  afficher"}</p>}
				</div>
			</div>
		)
	}, [displayElements, isEnglish]);

	const getComponent = useCallback(() => {
		if (!size.width)
			return null; //we'll have it next frame
		if (size.width > 800) {
			return (
				<div className={"filtered-grid-with-sidebar"}>
					{sideBar}
					{gridContainer}
				</div>
			);
		} else
			return (
				<div className={"filtered-grid-with-topbar"}>
					{topBar}
					{gridContainer}
				</div>
			)
	}, [size, sideBar, gridContainer, topBar]);

	return (
		getComponent()
	)
}

export default FilteredGrid;