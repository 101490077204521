import React from 'react';
import "./Pages.PrivacyPolicy.scss"

interface Props {
	isEnglish: boolean;
}

const PagesPrivacyPolicy = ({isEnglish}: Props) => {
	return <div id={"privacy-policy"}>
		<h3 id={"privacy-policy-title"}>{isEnglish ? "Privacy Policy" : "Politique de confidentialité"}</h3>
		<div id={"privacy-policy-content"}>
			<h4>{"Introduction"}</h4>
			<p>
				{
					isEnglish ?
						"Faced with the development of new communication tools, it is necessary to pay particular \n" +
						"attention to the protection of privacy. This is why we are committed to respecting the \n" +
						"confidentiality of the personal information we collect." :
						"Devant le développement des nouveaux outils de communication, il est nécessaire de porter une \n" +
						"attention particulière à la protection de la vie privée. C’est pourquoi, nous nous engageons à \n" +
						"respecter la confidentialité des renseignements personnels que nous collectons."
				}
			</p>
			<h4>{isEnglish ? "Collection of personal information" : "Collecte des renseignements personnels"}</h4>
			<ul>
				<li>{isEnglish ? "First name" : "Prénom"}</li>
				<li>{isEnglish ? "Address" : "Adresse postale"}</li>
				<li>{isEnglish ? "Postal code" : "Code postal"}</li>
				<li>{isEnglish ? "Email address" : "Adresse électronique"}</li>
				<li>{isEnglish ? "Telephone / fax number" : "Numéro de téléphone / télécopieur"}</li>
			</ul>
			<p>
				{
					isEnglish ?
						"The personal information we collect is collected through forms and through the interactivity \n" +
						"established between you and our website. We also use, as indicated in the next section, cookies \n" +
						"and/or log files to gather information about you." :
						"Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et \n" +
						"grâce à l’interactivité établie entre vous et notre site Web. Nous utilisons également, comme \n" +
						"indiqué dans la section suivante, des fichiers témoins et/ou journaux pour réunir des informations \n" +
						"vous concernant."
				}
			</p>
			<h4>{isEnglish ? "Forms and interactivity:" : "Formulaires et interactivité :"}</h4>
			<h5>
				{
					isEnglish ?
						"Your personal information is collected through a form, namely :" :
						"Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :"
				}
			</h5>
			<ul>
				<li>{isEnglish ? "Website registration form" : "Formulaire d'inscription au site Web"}</li>
				<li>{isEnglish ? "Order form" : "Formulaire de commande"}</li>
			</ul>
			<h5>
				{
					isEnglish ?
						"We use the information thus collected for the following purposes :" :
						"Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :"
				}
			</h5>
			<ul>
				<li>{isEnglish ? "Order Tracking" : "Suivi de la commande"}</li>
				<li>{isEnglish ? "Information / Promotional offers" : " Informations / Offres promotionnelles"}</li>
				<li>{isEnglish ? "Statistics" : "Statistiques"}</li>
				<li>{isEnglish ? "Contact" : " Contact"}</li>
			</ul>
			<h5>
				{
					isEnglish ?
						"Your information is also collected through the interactivity that may be established \n" +
						"between you and our website, as follows :" :
						"Vos renseignements sont également collectés par le biais de l’interactivité pouvant s’établir \n" +
						"entre vous et notre site Web et ce, de la façon suivante :"
				}
			</h5>
			<ul>
				<li>{isEnglish ? "Comments" : "Commentaires"}</li>
				<li>{isEnglish ? " Correspondence" : " Correspondance"}</li>
			</ul>
			<h5>
				{
					isEnglish ?
						"We use the information thus collected for the following purposes :" :
						"Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :"
				}
			</h5>
			<ul>
				<li>{isEnglish ? "Information or for promotional offers" : "Informations ou pour des offres promotionnelles"}</li>
				<li>{isEnglish ? "Statistics" : "Statistiques"}</li>
				<li>{isEnglish ? "Contact" : "Contact"}</li>
			</ul>
			<h4>{isEnglish ? "Right of opposition and withdrawal" : "Droit d’opposition et de retrait"}</h4>
			<p>
				{
					isEnglish ?
						"We are committed to offering you the right to oppose and withdraw your personal information.\n" +
						"The right of opposition is understood as the possibility offered to Internet users to refuse that \n" +
						"their personal information be used for certain purposes mentioned during the collection.\n" +
						"The right of withdrawal is understood as the possibility offered to Internet users to request that \n" +
						"their personal information no longer appear, for example, in a mailing list" :
						"Nous nous engageons à vous offrir un droit d’opposition et de retrait quant à vos renseignements \n" +
						"personnels.\n" +
						"Le droit d’opposition s’entend comme étant la possiblité offerte aux internautes de refuser que \n" +
						"leurs renseignements personnels soient utilisées à certaines fins mentionnées lors de la collecte.\n" +
						"Le droit de retrait s’entend comme étant la possiblité offerte aux internautes de demander à ce \n" +
						"que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion."
				}
			</p>
			<h4>{isEnglish ? "To be able to exercise these rights, you can :" : "Pour pouvoir exercer ces droits, vous pouvez :"}</h4>
			<p>{isEnglish ? "Email: info@sifacilecuisine.com" : "Courriel : info@sifacilecuisine.com"}</p>
			<h4>{isEnglish ? "Permission to access" : "Droit d’accès"}</h4>
			<p>
				{
					isEnglish ?
						"We are committed to recognizing a right of access and rectification to the persons concerned \n" +
						"wishing to consult, modify, or even delete the information concerning them." :
						"Nous nous engageons à reconnaître un droit d’accès et de rectification aux personnes concernées \n" +
						"désireuses de consulter, modifier, voire radier les informations les concernant."
				}
			</p>
			<h4>{isEnglish ? "The exercise of this right will be done :" : "L’exercice de ce droit se fera :"}</h4>
			<p>{isEnglish ? "Email: info@sifacilecuisine.com" : "Courriel : info@sifacilecuisine.com"}</p>
			<h4>{isEnglish ? "Security" : "Sécurité"}</h4>
			<p>
				{ isEnglish ?
					"The personal information we collect is kept in a secure environment. People working for us are \n" +
					"required to respect the confidentiality of your information." :
					"Les renseignements personnels que nous collectons sont conservés dans un environnement \n" +
					"sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos \n" +
					"informations."
				}
			</p>
			<h4>{isEnglish ? "To ensure the security of your personal information, we use the following measures :" : "Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures \n" +
				"suivantes :"}</h4>
			<ul>
				<li>{isEnglish ? "Access management - authorized person" : "Gestion des accès - personne autorisée"}</li>
			</ul>
			<p>
				{ isEnglish ?
					"We are committed to maintaining a high degree of confidentiality by integrating the latest \n" +
					"technological innovations to ensure the confidentiality of your transactions. However, since no \n" +
					"mechanism offers maximum security, some risk is always present when using the Internet to \n" +
					"transmit personal information." :
					"Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières \n" +
					"innovations technologiques permettant d’assurer la confidentialité de vos transactions. Toutefois, \n" +
					"comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente \n" +
					"lorsque l’on utilise Internet pour transmettre des renseignements personnels."
				}
			</p>
			<h4>{isEnglish ? "Legislation" : "Législation"}</h4>
			<p>
				{isEnglish ?
					"We are committed to respecting the legislative provisions set out in:\n" +
					"Legislation: Act respecting the protection of personal information in the private sector." :
					"Nous nous engageons à respecter les dispositions législatives énoncées dans :\n" +
					"Législation: Loi sur la protection des renseignements personnels dans le secteur privé."
				}
			</p>
		</div>
	</div>
}

export default PagesPrivacyPolicy;