import React, {useCallback, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import RoutedApp from "./RoutedApp";
import {useFirestoreQuery} from "@react-query-firebase/firestore";
import {collection, query} from "firebase/firestore";
import {firestore, storage} from "./Firebase/firebase";
import {Product} from "./Models/Product";
import {getDownloadURL, ref} from "firebase/storage";
import {Category} from "./Models/Category";
import {Recipe} from "./Models/Recipe";
import {PartnersEnterprise} from "./Models/PartnersEnterprise";
import {PartnersParticular} from "./Models/PartnersParticular";
import {MapMerchant} from "./Models/MapMerchant";

/**
 * HOC of our app from where react-query can be run
 * @constructor
 */

const QueryableApp = () => {
	//#region Product Query
	const productsQuery = useFirestoreQuery(
		["Products"],
		query(collection(firestore, "Products")),
		{subscribe: false},
		{
			retry: false
		}
	);

	const [products, setProducts] = useState<Product[]>([]);

	//just resolve the images url from the paths
	const generateProductsFromFirestoreProducts = useCallback(async (firestoreProducts: Product[]) => {
		const promises = firestoreProducts.map(async (firestoreProduct) => {
			let product: Product  = {
				...firestoreProduct,
				imagePath:  await getDownloadURL(ref(storage, firestoreProduct.imagePath)),
				nutritionPath: await getDownloadURL(ref(storage, firestoreProduct.nutritionPath)),
			}

			return product;
		})

		const resolvedPromises = await Promise.all(promises);
		setProducts(resolvedPromises);
	}, []);

	if (!productsQuery.isLoading && products.length === 0) {
		const datas: Product[] = productsQuery.data?.docs.map((snapshot) => {
			return snapshot.data() as Product;
		}) ?? [];

		const sortedDatas = datas.sort((a, b) => {
			if (a.displayRank < b.displayRank)
				return -1;
			if (a.displayRank === b.displayRank)
				return 0;
			return 1;
		});

		//Can't do async within an useEffect, delegate the async work in a useCallback.
		//Promise can be ignored as the appropriated work is done within it.
		generateProductsFromFirestoreProducts(sortedDatas);
	}

	//#endregion

	//#region Recipes Query

	const recipesQuery = useFirestoreQuery(
		["Recipes"],
		query(collection(firestore, "Recipes")),
		{subscribe: false},
		{
			retry: false
		}
	);

	const [recipes, setRecipes] = useState<Recipe[]>([]);

	//just resolve the images url from the paths
	const generateRecipesFromFirestoreRecipes = useCallback(async (firestoreRecipes: Recipe[]) => {
		const promises = firestoreRecipes.map(async (firestoreRecipe) => {
			let imageDownloadURL: string;

			try {
				imageDownloadURL = await getDownloadURL(ref(storage, firestoreRecipe.imagePath));
			} catch (e: any) {
				imageDownloadURL = "Failed";
			}

			let product: Recipe  = {
				...firestoreRecipe,
				imagePath:  imageDownloadURL,
			}

			return product;
		});

		const resolvedPromises = (await Promise.all(promises)).filter(recipe => {
			return recipe.imagePath !== "Failed";
		});

		setRecipes(resolvedPromises);
	}, []);

	if (!recipesQuery.isLoading && recipes.length === 0) {
		const datas: Recipe[] = recipesQuery.data?.docs.map((snapshot) => {
			return snapshot.data() as Recipe;
		}) ?? [];

		const sortedDatas = datas.sort((a, b) => {
			if (a.displayRank < b.displayRank)
				return -1;
			if (a.displayRank === b.displayRank)
				return 0;
			return 1;
		});

		//Can't do async within an useEffect, delegate the async work in a useCallback.
		//Promise can be ignored as the appropriated work is done within it.
		generateRecipesFromFirestoreRecipes(sortedDatas);
	}

	//#endregion

	//#region Categories Query

	const categoriesQuery = useFirestoreQuery(
		["Categories"],
		query(collection(firestore, "Categories")),
		{subscribe: false},
		{
			retry: false
		}
	);

	const [categories, setCategories] = useState<Category[]>([]);

	if (!categoriesQuery.isLoading && categories.length === 0) {
		const datas: Category[] = categoriesQuery.data?.docs.map((snapshot) => {
			return snapshot.data() as Category;
		}) ?? [];

		const sortedDatas = datas.sort((a, b) => {
			if (a.displayRank < b.displayRank)
				return -1;
			if (a.displayRank === b.displayRank)
				return 0;
			return 1;
		});

		setCategories(sortedDatas);
	}

	//#endregion

	//#region PartnersEnterprise Query

	const partnersEnterpriseQuery = useFirestoreQuery(
		["PartnersEnterprise"],
		query(collection(firestore, "PartnersEnterprise")),
		{subscribe: false},
		{
			retry: false
		}
	);

	const [partnersEnterprise, setPartnersEnterprise] = useState<PartnersEnterprise[]>([]);

	//just resolve the images url from the paths
	const generatePartnersEnterpriseFromFirestore = useCallback(async (firestorePartnersEnterprises: PartnersEnterprise[]) => {
		const promises = firestorePartnersEnterprises.map(async (firestorePartnerEnterprise) => {
			let partnerEnterprise: PartnersEnterprise  = {
				...firestorePartnerEnterprise,
				logoPath:  await getDownloadURL(ref(storage, firestorePartnerEnterprise.logoPath)),
			}

			return partnerEnterprise;
		})

		const resolvedPromises = await Promise.all(promises);
		setPartnersEnterprise(resolvedPromises);
	}, []);

	if (!partnersEnterpriseQuery.isLoading && partnersEnterprise.length === 0) {
		const datas: PartnersEnterprise[] = partnersEnterpriseQuery.data?.docs.map((snapshot) => {
			return snapshot.data() as PartnersEnterprise;
		}) ?? [];

		//Can't do async within an useEffect, delegate the async work in a useCallback.
		//Promise can be ignored as the appropriated work is done within it.
		generatePartnersEnterpriseFromFirestore(datas);
	}

	//#endregion

	//#region PartnersParticular Query

	const partnersParticularQuery = useFirestoreQuery(
		["PartnersParticular"],
		query(collection(firestore, "PartnersParticular")),
		{subscribe: false},
		{
			retry: false
		}
	);

	const [partnersParticular, setPartnersParticular] = useState<PartnersParticular[]>([]);


	if (!partnersParticularQuery.isLoading && partnersParticular.length === 0) {
		const datas: PartnersParticular[] = partnersParticularQuery.data?.docs.map((snapshot) => {
			return snapshot.data() as PartnersParticular;
		}) ?? [];

		setPartnersParticular(datas);
	}

	//#endregion

	//#region MapMerchant Query

	const mapMerchantQuery = useFirestoreQuery(
		["MapMerchants"],
		query(collection(firestore, "MapMerchants")),
		{subscribe: false},
		{
			retry: false
		}
	);

	const [mapMerchants, setMapMerchants] = useState<MapMerchant[]>([]);


	if (!mapMerchantQuery.isLoading && mapMerchants.length === 0) {
		const datas: MapMerchant[] = mapMerchantQuery.data?.docs.map((snapshot) => {
			return snapshot.data() as MapMerchant;
		}) ?? [];

		setMapMerchants(datas);
	}

	//#endregion

	return (
			<BrowserRouter>
				<RoutedApp
					products={products}
					categories={categories}
					recipes={recipes}
					partnersEnterprises={partnersEnterprise}
					partnersParticular={partnersParticular}
					mapMerchants={mapMerchants}
				/>
			</BrowserRouter>
	);
}
export default QueryableApp;