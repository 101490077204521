import React, {useCallback, useMemo} from "react";
import {Recipe} from "../../../Models/Recipe";

import "./Pages.Recipes.Recipe.scss"
import {Size, useWindowSize} from "../../../Hooks/useWindowSize";

interface Props {
	recipe: Recipe;
	isEnglish: boolean;
}

const PagesRecipesRecipe = ({recipe, isEnglish}: Props) => {
	const size: Size = useWindowSize();
	const notes = isEnglish ? recipe.notesEnglish : recipe.notesFrench;

	const wideSummary = useMemo(() => {
		return (
			<div id={"recipe-summary-wide"}>
				<img
					id={"recipe-summary-wide-image"}
					src={recipe.imagePath}
					alt={isEnglish ? "Image unavailable" : "Image non disponible"}
				/>
				<div id={"recipe-summary-wide-info"}>
					<h3>{isEnglish ? recipe.nameEnglish : recipe.nameFrench}</h3>
					<div className={"rec-buttons cooking"}>{(isEnglish ? "Cooking time: " : "Temps de cuisson: ") + recipe.cookingTime}</div>
					<div className={"rec-buttons prep"}>{(isEnglish ? "Preparation time: " : "Temps de préparation: ") + recipe.preparationTime}</div>
					<div className={"rec-buttons portions"}>{(isEnglish ? "Portions: " : "Portions: ") +  recipe.portions}</div>
					{
						notes &&
              <div id={"recipe-summary-wide-info-notes"}>
                  <h4>Notes</h4>
                  <ul>
										{notes.map(note => {
											return <li>{note}</li>
										})}
                  </ul>
              </div>
					}
				</div>
			</div>
		)
	}, [isEnglish]);

	const compactSummary = useMemo(() => {
		return (
			<div id={"recipe-summary-compact"}>
				<h3>{isEnglish ? recipe.nameEnglish : recipe.nameFrench}</h3>
				<img
					id={"recipe-summary-compact-image"}
					src={recipe.imagePath}
					alt={isEnglish ? "Image unavailable" : "Image non disponible"}
				/>
				<div id={"recipe-summary-compact-info"}>
					<div id={"recipe-summary-compact-info-header"}>
						<div className={"rec-buttons cooking"}>{(isEnglish ? "Cooking time: " : "Temps de cuisson: ") + recipe.cookingTime}</div>
						<div className={"rec-buttons prep"}>{(isEnglish ? "Preparation time: " : "Temps de préparation: ") + recipe.preparationTime}</div>
						<div className={"rec-buttons portions"}>{(isEnglish ? "Portions: " : "Portions: ") +  recipe.portions}</div>
					</div>
					{
						notes &&
              <div id={"recipe-summary-compact-info-notes"}>
                  <h4>Notes</h4>
                  <ul>
										{notes.map(note => {
											return <li>note</li>
										})}
                  </ul>
              </div>
					}
				</div>
			</div>
		)
	}, [isEnglish]);

	const getSummary = useMemo(() => {
		if (!size.width)
			return null; //we'll have it next frame
		if (size.width > 800) {
			return wideSummary;
		} else
			return compactSummary;
	}, [size,wideSummary, compactSummary]);

	return <div id={"recipe"}>
		{getSummary}
		<div id={"recipe-instruction"}>
			<div id={"recipe-instruction-ingredients"}>
				<h4>{isEnglish ? "Ingredients" : "Ingrédients"}</h4>
				<ul>
					{isEnglish ? recipe.ingredientsEnglish.map(ingredient => <li>{ingredient}</li>) : recipe.ingredientsFrench.map(ingredient => <li>{ingredient}</li>)}
				</ul>
			</div>
			<hr/>
			<div id={"recipe-instruction-steps"}>
				<h4>{isEnglish ? "Preparation" : "Préparation"}</h4>
				<ol>
					{isEnglish ? recipe.stepsEnglish.map(step => <li>{step}</li>) : recipe.stepsFrench.map(step => <li>{step}</li>)}
				</ol>
			</div>
		</div>
	</div>;
}

export default PagesRecipesRecipe