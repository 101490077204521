import React, {useEffect} from 'react';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import PagesHomepage from "./Components/Pages/Homepage/Pages.Homepage";
import PagesProducts from "./Components/Pages/Products/Pages.Products";
import PagesRecipes from "./Components/Pages/Recipes/Pages.Recipes";
import PagesVideos from "./Components/Pages/Videos/Pages.Videos";
import PagesMap from "./Components/Pages/Map/Pages.Map";
import PagesInfo from "./Components/Pages/Info/Pages.Info";
import PagesContact from "./Components/Pages/Contact/Pages.Contact";
import {Product} from "./Models/Product";
import {Category} from "./Models/Category";
import {Recipe} from "./Models/Recipe";
import PagesMerchant from "./Components/Pages/Merchant/Pages.Merchant";
import PagesPartners from "./Components/Pages/Partners/Pages.Partners";
import {PartnersEnterprise} from "./Models/PartnersEnterprise";
import {PartnersParticular} from "./Models/PartnersParticular";
import {MapMerchant} from "./Models/MapMerchant";
import PagesPrivacyPolicy from "./Components/Pages/PrivacyPolicy/Pages.PrivacyPolicy";

interface Props {
	products: Product[];
	categories: Category[];
	recipes: Recipe[];

	partnersEnterprises: PartnersEnterprise[];
	partnersParticular: PartnersParticular[];
	mapMerchants: MapMerchant[];
}

/**
 * HOC of our app from where the router take place, should be nested inside of the queryable app to allow query
 * to happen before routing hence prohibiting query be trigger by routing
 * @constructor
 */
const RoutedApp = ({products, categories, recipes, partnersParticular, partnersEnterprises, mapMerchants}: Props) => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname === "" || location.pathname === "/") {
			navigate("/fr/");
		}
	}, [location]);

	return (
		<div id="App">
			<Routes>
				<Route path={"/en/*"} element={<Header isEnglish={true} recipes={recipes}/>}/>
				<Route path={"/fr/*"} element={<Header isEnglish={false} recipes={recipes}/>}/>
			</Routes>
			<Routes>
				<Route path={"/en/"} element={<PagesHomepage isEnglish={true} products={products}/>}/>
				<Route path={"/en/products"} element={<PagesProducts isEnglish={true} products={products}/>}/>
				<Route path={"/en/recipes"} element={<PagesRecipes isEnglish={true} categories={categories} recipes={recipes}/>}/>
				<Route path={"/en/videos"} element={<PagesVideos isEnglish={true} categories={categories} recipes={recipes}/>}/>
				<Route path={"/en/stores"} element={<PagesMap isEnglish={true} mapMerchants={mapMerchants}/>}/>
				<Route path={"/en/infos"} element={<PagesInfo isEnglish={true}/>}/>
				<Route path={"/en/contact"} element={<PagesContact isEnglish={true}/>}/>
				<Route path={"/en/become-a-merchant"} element={<PagesMerchant isEnglish={true}/>}/>
				<Route path={"/en/partners"} element={<PagesPartners isEnglish={true} partnersParticular={partnersParticular} partnersEnterprise={partnersEnterprises}/>}/>
				<Route path={"/en/privacy-policy"} element={<PagesPrivacyPolicy isEnglish={true}/>}/>

				<Route path={"/fr/"} element={<PagesHomepage isEnglish={false} products={products}/>}/>
				<Route path={"/fr/produits"} element={<PagesProducts isEnglish={false} products={products}/>}/>
				<Route path={"/fr/recettes"} element={<PagesRecipes isEnglish={false} categories={categories} recipes={recipes}/>}/>
				<Route path={"/fr/videos"} element={<PagesVideos isEnglish={false} categories={categories} recipes={recipes}/>}/>
				<Route path={"/fr/magasins"} element={<PagesMap isEnglish={false} mapMerchants={mapMerchants}/>}/>
				<Route path={"/fr/infos"} element={<PagesInfo isEnglish={false}/>}/>
				<Route path={"/fr/contact"} element={<PagesContact isEnglish={false}/>}/>
				<Route path={"/fr/devenir-un-marchand"} element={<PagesMerchant isEnglish={false}/>}/>
				<Route path={"/fr/partenaires"} element={<PagesPartners isEnglish={false} partnersParticular={partnersParticular} partnersEnterprise={partnersEnterprises}/>}/>
				<Route path={"/fr/confidentialité"} element={<PagesPrivacyPolicy isEnglish={false}/>}/>
			</Routes>
			<Routes>
				<Route path={"/en/*"} element={<Footer isEnglish={true}/>}/>
				<Route path={"/fr/*"} element={<Footer isEnglish={false}/>}/>
			</Routes>
		</div>
	);
}
export default RoutedApp;