// Import the functions you need from the SDKs you need
import { getApp, initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage"
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD4mbo1XNn4O-D0-QGK7oC2KQ32qqVRCfs",
    projectId: "sifacile-c4543",
    storageBucket: "sifacile-c4543.appspot.com",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);
