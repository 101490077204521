import React from 'react';
import "./Pages.Partners.scss";
import FilteredGrid from "../../GenericComponents/GridWithCategoriesFilter/FilteredGrid";
import {PartnersEnterprise} from "../../../Models/PartnersEnterprise";
import {PartnersParticular} from "../../../Models/PartnersParticular";

interface Props {
  isEnglish: boolean;
	partnersEnterprise: PartnersEnterprise[];
	partnersParticular: PartnersParticular[];
}

const PagesPartners = ({isEnglish, partnersParticular, partnersEnterprise}:Props) => {
	return <div id={"partners"}>
		<h3 id={"partners-title"}>{isEnglish ? "Partners" : "Partenaires"}</h3>
		<div id={"partners-enterprises"}>
			<p id={"partners-enterprises-text"}>
				{isEnglish ?
					"Thank you to the various partners who supported us during our crowdfunding campaign!" :
					"Merci aux différents partenaires qui nous ont soutenus lors de notre campagne de sociofinancement!"
				}
			</p>
			{partnersEnterprise.map(enterprise => {
				return <div className={"partners-enterprises-enterprise"}>
					<div>
						<img
							src={enterprise.logoPath}
							alt={isEnglish ? "Image unavailable" : "Image non disponible"}
						/>
						<p>{enterprise.name}</p>
					</div>
					<p>
						{isEnglish ? enterprise.textEnglish : enterprise.textFrench}
					</p>
				</div>
			})}
		</div>
	</div>
}

export default PagesPartners;