import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./Footer.scss"
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../Firebase/firebase";
import {Size, useWindowSize} from "../../Hooks/useWindowSize";

interface Props {
	isEnglish: boolean
}

const Footer = ({isEnglish}: Props) => {
	const size: Size = useWindowSize();

	//#region fetchImage

	const [headerLogoURL, setHeaderLogoURL] = useState<string>("");
	const [fbLogoURL, setFbLogoURL] =useState<string>("");
	const [instaLogoURL, setInstaLogoURL] =useState<string>("");
	const [tiktokLogoURL, setTiktokLogoURL] =useState<string>("");

	useEffect(() => {
		getDownloadURL(ref(storage, "logo_header.png")).then((url) => {
			setHeaderLogoURL(url);
		});

		getDownloadURL(ref(storage, "icons8-facebook.svg")).then((url) => {
			setFbLogoURL(url);
		});

		getDownloadURL(ref(storage, "icons8-instagram.svg")).then((url) => {
			setInstaLogoURL(url);
		});

		getDownloadURL(ref(storage, "icons8-tiktok.svg")).then((url) => {
			setTiktokLogoURL(url);
		});
	}, []);

	const openFacebookInNewPage = useCallback(() => {
		window.open("https://www.facebook.com/profile.php?id=100077663182733" , "_blank");
	}, []);

	const openInstaInNewPage = useCallback(() => {
		window.open( "https://www.instagram.com/sifacilecuisine/?fbclid=IwAR0lQjxc4O9VXqTwq6Onqz3AvV3i4Q-hTpcq_SqrpMQUSJ3FwhekKUazpNU", "_blank");
	}, []);

	const openTikTokInNewPage = useCallback(() => {
		window.open("https://www.tiktok.com/@sifacilecuisine?fbclid=IwAR051JkwNZGnmTMwRZN-kp5bUmtRKwBg7aJoQERJO1l-FD1CY5prFO2U5QU", "_blank");
	}, []);

	//endregion fetchImage

	const navBar = useMemo(() => {
		return (
			<div className="footer-navBar">
				<div>
					<NavLink to={isEnglish ? "/en/contact/" : "/fr/contact/"} className={"footer-navLink"}>
						<div>Contact</div>
					</NavLink>
					<NavLink to={isEnglish ? "/en/become-a-merchant/" : "/fr/devenir-un-marchand/"} className={"footer-navLink"}>
						<div>{isEnglish ? "Become a merchant" : "Devenir un marchand"}</div>
					</NavLink>
				</div>
				<div>
					<NavLink to={isEnglish ? "/en/partners/" : "/fr/partenaires/"} className={"footer-navLink"}>
						<div>{isEnglish ? "Partners" : "Partenaires"}</div>
					</NavLink>
					<NavLink to={isEnglish ? "/en/privacy-policy" : "/fr/confidentialité"} className={"footer-navLink"}>
						<div>{isEnglish ? "Privacy Policy" : "Politique de confidentialité"}</div>
					</NavLink>
				</div>
			</div>
		)
	}, [isEnglish]);

	const options = useMemo(() => {
		return (
			<div id={"footer-options"}>
				<img src={fbLogoURL} alt={"fb"} onClick={openFacebookInNewPage}/>
				<img src={instaLogoURL} alt={"insta"} onClick={openInstaInNewPage}/>
				{/*<img src={tiktokLogoURL} alt={"tiktok"} onClick={openTikTokInNewPage}/>*/}
			</div>
		)
	}, [tiktokLogoURL, instaLogoURL, fbLogoURL]);

	const logo = useMemo(() => {
		return (
			<NavLink to={isEnglish ? "/en/" : "/fr/"}>
				<img id="footer-title" src={headerLogoURL} alt={"Si Facile"}/>
			</NavLink>
		)
	}, [isEnglish, headerLogoURL]);

	const wideFooter = useMemo(() => {
		return (
			<div id="footer-wide">
				<div id="footer-wide-column-one">
					{navBar}
				</div>
				<div id={"footer-wide-column-two"}>
					{logo}
				</div>
				<div id="footer-wide-column-three">
					{options}
				</div>
			</div>
		)
	}, [navBar, options, logo]);

	const compactFooter = useMemo(() => {
		return (
			<div id="footer-compact">
				<div id="footer-compact-menu">
					{navBar}
				</div>
				<div id="footer-compact-logo">
					{logo}
				</div>
			</div>
		)
	}, [navBar, logo]);

	const footer = useMemo(() => {
		if (!size.width)
			return null; //we'll have it next frame
		if (size.width > 800)
			return wideFooter;
		else
			return compactFooter;
	}, [wideFooter, compactFooter, size]);

	return footer;
}

export default Footer;