import React, {useEffect, useState} from 'react';
import "./Pages.Info.scss";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../../Firebase/firebase";
import {NavLink} from "react-router-dom";

interface Props {
	isEnglish: boolean
}


const PagesInfo = ({isEnglish}: Props) => {
	const [info1URL, setInfo1URL] = useState<string>("");
	const [info2URL, setInfo2URL] =useState<string>("");

	useEffect(() => {
		getDownloadURL(ref(storage, "info1.jpg")).then((url) => {
			setInfo1URL(url);
		});

		getDownloadURL(ref(storage, "info2.jpg")).then((url) => {
			setInfo2URL(url);
		});

	}, []);

	return <div id="info">
		<h3 id="info-title">{isEnglish ? "About" : "À propos"}</h3>
		<div id={"info-about"}>
			<div id={"info-about-row1"}>
				<img
					src={info1URL}
					alt={isEnglish ? "Image unavailable" : "Image non disponible"}
				/>
				<div className={"about-text"}>
					<p>
						{isEnglish ?
							"Si Facile wishes to promote health and the environment through its various plant products offered in returnable glass jars" :
							"Si Facile désire promouvoir la santé et l’environnement grâce à ses différents produits végétaux offerts dans des pots en verre consignables."
						}
					</p>
					<p>
						{isEnglish ?
							"Our first product line consists of ready-to-eat chickpeas, kidney beans, black beans and pinto beans." :
							"Notre première gamme de produits est composée de pois chiches, de haricots rouges, de haricots noirs et de haricots pinto prêts à être consommés."
						}
					</p>
					<p>
						{isEnglish ?
						 "At Si Facile, we believe it is important to promote local products. This is why our legumes come mainly from Quebec (as soon as possible, if not from Western Canada)" :
							"À Si Facile, nous sommes d’avis qu’il est important de mettre de l’avant les produits d’ici. C’est pourquoi nos légumineuses proviennent majoritairement du Québec (dès que c’est possible, sinon de l’Ouest canadien)."
						}
					</p>
				</div>
			</div>
			<div id={"info-about-row2"}>
				<div className={"about-text"}>
					<h4>
						{isEnglish ? "Where did the idea for Si Facile come from?" : "D’où provient l’idée de Si Facile?"}
					</h4>
					<p>
						{isEnglish ?
							"Gabrielle, the founder, had the idea to create the company when she saw the lack options currently offered to Quebecers regarding the consumption of legumes." :
							"Gabrielle, la fondatrice, a eu l’idée de créer l’entreprise lorsqu’elle a constaté le manque d’options présentement offert aux Québécois concernant la consommation des légumineuses."
						}
					</p>
					<p>
						{isEnglish ?
							"The first option is aluminum cans. The problems with this option:" :
							"La première option est celle des conserves en aluminium. Les problèmes avec cette option :"
						}
					</p>
					<ul>
						<li>
							{isEnglish ?
								"The extraction and production of aluminum is very harmful to the environment." :
								"L’extraction et la production d’aluminium sont très nocives pour l’environnement."
							}
						</li>
						<li>
							{isEnglish ?
								"The recycling system in Quebec does not recycle the majority of aluminum and aluminum is usually sent to other countries for recycling." :
								"Le système de recyclage au Québec ne recycle pas la majorité de l’aluminium et l’aluminium est la plupart du temps envoyé dans d’autres pays pour son recyclage."
							}
						</li>
						<li>
							{isEnglish ?
								"These are legumes that usually come from the United States or Europe." :
								"Ce sont des légumineuses qui proviennent généralement des États-Unis ou d’Europe."
							}
						</li>
						<li>
							{isEnglish ?
								"The brine of most aluminum cans contains food additives such as calcium chloride, disodium EDTA, citric acid and sodium metabisulphite." :
								"La saumure de la majorité des conserves en aluminium contient des additifs alimentaires comme du chlorure de calcium, du EDTA disodique, de l’acide citrique et du métabisulfite de sodium."
							}
						</li>
					</ul>
					<p>
						{isEnglish ?
							"The second option is the bulk one. This option requires a lot of organization since it\n" +
							"is necessary to bring its own containers to purchase the products, to plan the\n" +
							"soaking time (about 12 hours), to plan the cooking time (about 1 hour) and all this before\n" +
							"even to cook his meal. It is an ecological option, but excessively long for a\n" +
							"product that could be purchased ready to use" :
							"La deuxième option est celle en vrac. Cette option demande beaucoup d’organisation puisqu’il \n" +
							"est nécessaire d’amener ses propres contenants pour faire l’achat des produits, de prévoir le \n" +
							"temps de trempage (environ 12h), de prévoir le temps de cuisson (environ 1h) et tout cela avant \n" +
							"même de cuisiner son repas. C’est une option écologique, mais excessivement longue pour un \n" +
							"produit qui pourrait être acheté prêt à être utilisé"
						}
					</p>
					<p>
						{isEnglish ?
							"Gabrielle therefore had the idea of creating legumes in returnable glass jars to\n" +
							"offer Quebecers a solution that respects the environment, but also a solution\n" +
							"very fast which encourages the economy here!" :
							"Gabrielle a donc eu l’idée de créer des légumineuses dans des pots en verres consignables pour \n" +
							"offrir aux Québécois une solution respectueuse de l’environnement, mais également une solution \n" +
							"très rapide qui encourage l’économie d’ici!"
						}
					</p>
				</div>
				<img
					src={info2URL}
					alt={isEnglish ? "Image unavailable" : "Image non disponible"}
				/>
			</div>
		</div>
		<h4>FAQ</h4>
		<div id={"info-faqs"}>
			<ol>
				<li>
					<h4>
						{isEnglish ?
							"How does our deposit system work?" :
							"Comment fonctionne notre système de consigne?"
						}
					</h4>
					<div>
						<p>
							{isEnglish ?
								"Return your empty and rinsed jar with the label to the various shops selling Si Facile products." :
								"Rapportez votre pot vide et rincé avec l'étiquette chez les différents commerces vendant les produits Si Facile."
							}
						</p>
						<div className={"pWithLink"}>
							<p>
								{isEnglish ?
									"To find out where our products are sold," :
									"Pour connaître où nos produits sont vendus,"
								}
							</p>
							<NavLink to={isEnglish ? "/en/stores/" : "/fr/magasins/"}>
								<p> {isEnglish ? "click here" : "cliquez ici"}</p>
							</NavLink>
							<p>.</p>
						</div>
						<p>
							{isEnglish ?
								"$1 will be given to you per returnable pot." :
								"1$ vous sera remis par pot consigné."
							}
						</p>
					</div>
				</li>
				<li>
					<h4>
						{isEnglish ?
							"Where to find our points of sale and collection points?" :
							"Où trouver nos points de vente et les points de récupération de consigne?"
						}
					</h4>
					<div>
						<div className={"pWithLink"}>
							<p>
								{isEnglish ?
									"You can bring back the pots\n" +
									"recorded at all retailers selling Si Facile products. To find retailers near you" :
									"Vous pouvez ramener les pots \n" +
									"consignés chez l’ensemble des détaillants vendant les produits Si Facile.  Pour connaître les détaillants près de chez vous "
								}
							</p>
							<NavLink to={isEnglish ? "/en/stores/" : "/fr/magasins/"}>
								<p>
									{isEnglish ?
										"click here" :
										"cliquez ici"
									}
								</p>
							</NavLink>
						</div>
					</div>
				</li>
				<li>
					<h4>
						{isEnglish ?
							"Where do our legumes come from?" :
							"D’où proviennent nos légumineuses?"
						}
					</h4>
					<div>
						<p>
							{isEnglish ?
								"The different beans are grown by the Haribec farm located in St-Aimé." :
								"Les différents haricots sont cultivés par la ferme Haribec située à St-Aimé. "
							}
						</p>
						<p>
							{isEnglish ?
								"Chickpeas are unfortunately not yet grown in Quebec. We buy them at\n" +
								"Haribec farm, which sources its supplies in Western Canada." :
								"Les pois chiches ne sont malheureusement pas encore cultivés au Québec. Nous les achetons à la \n" +
								"ferme Haribec qui s’approvisionne dans l’Ouest Canadien. "
							}
						</p>
					</div>
				</li>
				<li>
					<h4>
						{isEnglish ?
							"Our actions to protect the environment" :
							"Nos actions pour protéger l’environnement"
						}
					</h4>
					<div>
						<p>
							{isEnglish ?
								"We contribute to reducing the consumer's ecological footprint, both by buying local and\n" +
								"by the deposit and vegetable proteins that promote sustainable development." :
								"Nous contribuons à réduire l’empreinte écologique du consommateur, tant par l’achat local, que \n" +
								"par la consigne et les protéines végétales qui favorisent le développement durable."
							}
						</p>
						<p>
							{isEnglish ?
								"Also, the lids brought back with the returnable pots are sent directly to a center\n" +
								"Quebec recycling center to ensure their recycling. Same thing for returnable pots\n" +
								"damaged." :
								"Également, les couvercles rapportés avec les pots consignés sont envoyés directement à un centre \n" +
								"de recyclage québécois pour s’assurer de leurs recyclages. Même chose pour les pots consignés \n" +
								"abîmés."
							}
						</p>
					</div>
				</li>
			</ol>
		</div>
	</div>
}

export default PagesInfo;